/* The above class is a JavaScript GHL helper class that retrieves user data by sending a request to a server and
decrypting the response using a key. */

// {
//   activeLocation: "UHMyfpFQo4ytvUgEYBhP";
//   companyId: "TapH6FY4HH6wyRg7dCej";
//   email: "henry.chavez@xn--brjula-qya.com";
//   role: "admin";
//   type: "agency";
//   userId: "wWwZcHGYMEkTBgnLnsbG";
//   userName: "Henry Chavez";
// }
export class GHL {
  static instance;
  locationId;

  constructor() {
    if (GHL.instance) {
      throw new Error("New instance cannot be created!!");
    }
  }

  static getInstance() {
    if (!GHL.instance) {
      GHL.instance = new GHL();
    }

    return GHL.instance;
  }

  async getUserData() {
    const key = await new Promise((resolve) => {
      window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*");
      window.addEventListener("message", ({ data }) => {
        if (data.message === "REQUEST_USER_DATA_RESPONSE") {
          resolve(data.payload);
        }
      });
    });
    const res = await fetch("/decrypt-sso", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ key }),
    });
    const data = await res.json();
    this.locationId = data.activeLocation;

    return data;
  }

  async updatePhone(data) {
    // this.locationId = "J5KHY2jTZwWeWHLt6dsV"

    if (!this.locationId) return;

    const res = await fetch(`/location/${this.locationId}/number`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return res;
  }

  async getInstalationInfo() {
    if (!this.locationId) return;

    try {
      const res = await fetch(`/location/${this.locationId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data;
    } catch (error) {
      return undefined;
    }
  }
}
